import React from "react";
import { Route, Switch } from "react-router-dom";
import Helmet from "react-helmet";
import {
  OnlyUnauthenticatedRoute,
  CustomerQuery,
  SignOutLogic,
  CartQuery
} from "@deity/falcon-ecommerce-uikit";
import loadable from "src/components/loadable";
import DynamicRoute from "src/pages/DynamicRoute";
import { SidebarContainer } from "src/pages/shop/components/Sidebar";
import {
  ClubBenluxPage,
  Footer,
  Header,
  // GWPPopIn
  SalePopIn
} from "benlux-ecommerce-ui";
import { MenuQuery } from "../queries/MenuQuery";
import { FooterQuery } from "../queries/FooterQuery";

import { ProtectedRoute } from "./ProtectedRoute";
import { OpenSidebarMutation } from "../pages/shop/components/Sidebar/SidebarMutations";
import { Sidebar } from "../pages/shop/components/Sidebar/Sidebar";
import { SearchProvider } from "./Search";

import { NewsletterForm } from "./Newsletter";
import { ContactFormLayout } from "./ContactForm";
import { KilibaCustomerTracker } from "./KilibaTracker/KilibaCustomerTracker";

const Home = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/home" */ "../pages/Home"
  )
);
const Account = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "account" */ "../pages/shop/Account/Account"
  )
);
const SignIn = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "account/sign-in" */ "../pages/account/SignIn/SignIn"
  )
);
const SignUp = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "account/sign-up" */ "../pages/account/SignUp/SignUp"
  )
);
const BrandsPage = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/brands" */ "../pages/shop/Brands"
  )
);
const ResetPassword = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/resetpassword" */ "../pages/shop/ResetPassword"
  )
);
const Blog = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "blog/blog" */ "../pages/blog/Blog"
  )
);
const Cart = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/cart" */ "../pages/shop/Cart"
  )
);
const SidebarContents = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/SidebarContents" */ "../pages/shop/components/Sidebar/SidebarContents"
  )
);
const SearchPage = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/search" */ "../pages/SearchPage"
  )
);
const Help = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/help" */ "../pages/Help/Help"
  )
);
const CheckoutConfirmation = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/checkout-confirmation" */ "../pages/shop/CheckoutConfirmation"
  )
);
const OrderWithMask = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/order-with-mask" */ "../pages/shop/Account/Orders/OrderWithMask"
  )
);
const Inspirations = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/inspirations" */ "../pages/shop/Inspirations/Inspirations"
  )
);
const SiteMap = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/site-map" */ "../pages/shop/SiteMap/SiteMap"
  )
);
const ChanelCallAndDeliver = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/chanel" */ "../pages/shop/ChanelCallAndDeliver"
  )
);
// const ExpertiseDior = loadable(() => import(/* webpackPrefetch: true, webpackChunkName: "shop/expertise-dior" */ '../pages/shop/ExpertiseDior'));
const Newsletter = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/newsletter" */ "../pages/shop/Newsletter"
  )
);
const OctobreRose = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "shop/octobre-rose" */ "../pages/OctobreRose"
  )
);

const DefaultLayout = ({ history, algoliaSearchConfig }) => (
  <>
    <MenuQuery>
      {({
        menu,
        brandsContents,
        noveltiesContents,
        selectionsContents,
        inspirationsContents,
        giftsContents,
        reinsuranceContent,
        blackFridayContents,
        soldesContents,
        promotionnalBanner
      }) => (
        <CartQuery>
          {({ cart }) => (
            <CustomerQuery>
              {({ customer }) => (
                <SignOutLogic>
                  {({ signOut }) => (
                    <OpenSidebarMutation>
                      {openSidebar => (
                        <Header
                          contents={{
                            brandsContents,
                            noveltiesContents,
                            selectionsContents,
                            inspirationsContents,
                            giftsContents,
                            reinsuranceContent,
                            soldesContents,
                            blackFridayContents
                          }}
                          promotionnalBanner={promotionnalBanner}
                          searchProvider={<SearchProvider />}
                          history={history}
                          items={menu}
                          cart={cart}
                          account={customer}
                          loginPath={"/connexion"}
                          signOut={() => signOut()}
                          onPressOrder={() =>
                            openSidebar({ variables: { contentType: "cart" } })
                          }
                          algoliaSearchConfig={algoliaSearchConfig}
                        />
                      )}
                    </OpenSidebarMutation>
                  )}
                </SignOutLogic>
              )}
            </CustomerQuery>
          )}
        </CartQuery>
      )}
    </MenuQuery>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/blog/:page?" component={Blog} />
      <Route exact path="/panier" component={Cart} />
      <Route exact path="/search/:value?" component={SearchPage} />
      <Route exact path="/marques" component={BrandsPage} />
      <Route exact path="/chanel" component={ChanelCallAndDeliver} />
      {/* <Route exact path="/expertise-dior" component={ExpertiseDior}/> */}
      <Route exact path="/plan-du-site" component={SiteMap} />
      <Route exact path="/newsletter" component={Newsletter} />
      <Route
        exact
        path="/club-benlux"
        render={props => (
          <>
            <Helmet>
              <title>Rejoignez le Club et Devenez VIP</title>
              <meta name="description" content="" />
              <meta name="page-loaded" content="true" />
            </Helmet>
            <ClubBenluxPage {...props} />
          </>
        )}
      />
      <Route exac path="/octobre-rose" component={OctobreRose} />
      <Route exact path="/inscription" component={SignUp} />
      <Route
        exact
        path="/confirmation-commande"
        component={CheckoutConfirmation}
      />
      <Route exact path="/inspirations" component={Inspirations} />
      <Route exact path="/commande/:maskedId?" component={OrderWithMask} />
      <Route path="/aide" component={Help} />
      <Route
        path="/contactez-nous"
        render={props => (
          <>
            <Helmet>
              <title>Contactez-nous</title>
              <meta name="description" content="" />
              <meta name="page-loaded" content="true" />
            </Helmet>
            <ContactFormLayout {...props} />
          </>
        )}
      />
      <ProtectedRoute path="/compte" component={Account} />
      <OnlyUnauthenticatedRoute exact path="/connexion" component={SignIn} />
      <OnlyUnauthenticatedRoute
        exact
        path="/nouveau-mot-de-passe"
        component={ResetPassword}
      />
      <DynamicRoute history={history} />
    </Switch>
    <FooterQuery>
      {({ cmsBlock, trustpilotData }) => {
        if (cmsBlock) {
          const content = JSON.parse(cmsBlock.content);
          return (
            <Footer
              inputNewsletter={<NewsletterForm />}
              content={content}
              trustpilotData={trustpilotData}
            />
          );
        }

        return <></>;
      }}
    </FooterQuery>
    <SidebarContainer>
      {sidebarProps => (
        <Sidebar {...sidebarProps}>
          <SidebarContents
            contentType={sidebarProps.contentType}
            error={sidebarProps.error}
            contextKey={sidebarProps.contextKey}
            contextItemId={sidebarProps.contextItemId}
          />
        </Sidebar>
      )}
    </SidebarContainer>
    <SalePopIn />
    {/* <FreeShippingPopIn /> */}
    <KilibaCustomerTracker />
    {/*<NewsletterPopUp history={history}/>*/}
  </>
);

export default DefaultLayout;
